import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { Input, Grid, Radio, ButtonCircle, Spacer } from '../../../components';
import ApiService from '../../../api';
import styles from './contactForm.module.scss';

const ContactForm = ({ translations }) => {
	const [submitted, setSubmitted] = useState(false);

	const ContactSchema = Yup.object().shape({
		firstName: Yup.string().required('First name is required.'),
		lastName: Yup.string().required('Last name is required.'),
		email: Yup.string()
			.email('Invalid email.')
			.required('Email is required.'),
		confirmEmail: Yup.string()
			.oneOf([Yup.ref('email'), null], 'Emails must match')
			.required('Confirm email is required.'),
		message: Yup.string()
			.max(255, 'Max message length is 255 characters.')
			.required('Message is required.'),
	});

	const handleSubmit = async (values, actions) => {
		if (values['bot-field']) {
			return false;
		}

		const data = {
			contactName: `${values.prefix} ${values.firstName} ${values.lastName}`,
			contactOrganization: values.organization,
			contactEmail: values.email,
			contactMessage: values.message,
		};

		try {
			await ApiService.sendEmail(data);

			setSubmitted(true);
			actions.resetForm();

			setTimeout(() => {
				setSubmitted(false);
				actions.setStatus();
			}, 10000);
		} catch (error) {
			actions.setStatus({ error: 'Server side error' });
			console.error(error);
		} finally {
			setTimeout(() => {
				actions.setSubmitting(false);
			}, 5000);
		}
	};

	const renderForm = ({ handleSubmit, errors }) => (
		<form onSubmit={handleSubmit}>
			<Field
				isHoneyPot
				name="bot-field"
				id="bot-field"
				label="Don’t fill this out if you're human"
				autoComplete="random"
				component={Input}
			/>
			<Grid>
				<Grid.Col width="sm-12">
					<Grid>
						<Grid.Col width="xs">
							<Field
								type="radio"
								name="prefix"
								label={
									translations.contact_form_field_prefix_mr
								}
								value="Mr."
								component={Radio}
							/>
						</Grid.Col>
						<Grid.Col width="xs">
							<Field
								type="radio"
								name="prefix"
								label={
									translations.contact_form_field_prefix_mrs
								}
								value="Mrs."
								component={Radio}
							/>
						</Grid.Col>
					</Grid>
				</Grid.Col>
				<Grid.Col width="sm-12">
					<Field
						type="text"
						name="organization"
						id="organization"
						label={`${translations.contact_form_field_organization}`}
						autoComplete="organization"
						component={Input}
					/>
				</Grid.Col>
				<Grid.Col width="sm-12">
					<Field
						type="text"
						name="firstName"
						id="firstName"
						label={`${translations.contact_form_field_first_name}*`}
						autoComplete="given-name"
						component={Input}
					/>
				</Grid.Col>
				<Grid.Col width="sm-12">
					<Field
						type="email"
						name="email"
						id="email"
						label={`${translations.contact_form_field_email}*`}
						autoComplete="email"
						component={Input}
					/>
				</Grid.Col>
				<Grid.Col width="sm-12">
					<Field
						type="text"
						name="lastName"
						id="lastName"
						label={`${translations.contact_form_field_last_name}*`}
						autoComplete="family-name"
						component={Input}
					/>
				</Grid.Col>
				<Grid.Col width="sm-12">
					<Field
						type="email"
						name="confirmEmail"
						id="confirmEmail"
						label={`${translations.contact_form_field_confirm_email}*`}
						component={Input}
					/>
				</Grid.Col>
				<Grid.Col width="sm-auto">
					<Field
						type="textarea"
						name="message"
						id="message"
						label={`${translations.contact_form_field_message}*`}
						component={Input}
					/>
				</Grid.Col>
				<Grid.Col width="sm" className="text-center">
					<ButtonCircle type="submit">
						{translations.contact_form_field_send}
					</ButtonCircle>
				</Grid.Col>
			</Grid>
		</form>
	);

	return (
		<div className={styles.container}>
			<h2 className={cn('uppercase', styles.title)}>
				{translations.contact_form_header}
			</h2>
			{submitted ? (
				<>
					<Spacer mobile={30} />
					<h3 className={styles.overlay}>
						{translations.contact_form_thanks}
					</h3>
					<Spacer mobile={30} />
				</>
			) : (
				<Formik
					initialValues={{
						prefix: 'Mrs.',
						firstName: '',
						lastName: '',
						organization: '',
						email: '',
						confirmEmail: '',
						message: '',
					}}
					onSubmit={handleSubmit}
					validationSchema={ContactSchema}
				>
					{(props) => renderForm(props)}
				</Formik>
			)}
		</div>
	);
};

export default ContactForm;

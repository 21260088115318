import React from 'react';
import { graphql } from 'gatsby';

import { Layout, Container, Spacer, Background, Grid } from '../components';
import { ContactForm, ContactImage } from '../containers/contact';

const ContactPage = ({
	pageContext,
	data: {
		wordpressPage: { acf: translations },
	},
}) => (
	<Layout
		title={translations.seo_title}
		description={translations.seo_description}
		image={translations.seo_cover_image}
		pageContext={pageContext}
	>
		<Container innerRelative>
			<ContactImage
				image={translations.contact_background_image}
				alt={translations.contact_title}
			/>
		</Container>
		<Container left={236} right={236}>
			<div className="hidden-mobile">
				<Spacer desktop={70} />
				<Grid>
					<Grid.Col width="sm-10">
						<h1>{translations.contact_title}</h1>
					</Grid.Col>
				</Grid>
				<Spacer desktop={40} />
			</div>
			<Background wider light>
				<Spacer desktop={70} mobile={40} />
				<ContactForm translations={translations} />
				<Spacer desktop={120} mobile={50} />
			</Background>
		</Container>
	</Layout>
);

export default ContactPage;

export const query = graphql`
	query($id: Int, $lang: String) {
		wordpressPage(
			wordpress_id: { eq: $id }
			wpml_current_locale: { eq: $lang }
		) {
			wpml_current_locale
			acf {
				seo_title
				seo_description
				seo_cover_image
				contact_title
				contact_background_image
				contact_form_header
				contact_form_field_prefix_mr
				contact_form_field_prefix_mrs
				contact_form_field_organization
				contact_form_field_first_name
				contact_form_field_last_name
				contact_form_field_email
				contact_form_field_confirm_email
				contact_form_field_message
				contact_form_field_send
				contact_form_thanks
				seo_title
			}
		}
	}
`;

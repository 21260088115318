import React from 'react';

import style from './ContactImage.module.scss';

const ContactImage = ({ image, alt }) => {
	return (
		<div className={style['contactImage']}>
			<img
				className={style['contactImage__image']}
				loading="lazy"
				height="1280"
				width="1920"
				alt={alt}
				src={image}
			/>
		</div>
	);
};

export default ContactImage;

export const makeFetch = async (url, options) => {
	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			throw new Error(JSON.stringify(await response.json()));
		}

		return await response.json();
	} catch (e) {
		console.error(e);
		throw new Error(e);
	}
};

const BASE_URL = `${process.env.GATSBY_API_URL}/wp-json`;

class ApiService {
	static async sendEmail(body) {
		try {
			return makeFetch(`${BASE_URL}/email/send`, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (e) {
			console.error(e);
		}
	}
}

export default ApiService;
